<template>
  <div id="view-support">
    <article>
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/help_48x48.png" class="icon" alt="Support Icon" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5">Support</div>
            <p>
              Considering the nature of software, there are always support
              issues to be addressed with every project. In many ways, support
              for a product is as important as the product itself. While we are
              a small company, we pride ourselves upon our support of our work.
              Due to our size, we cannot at this time offer phone support. We do
              provide three support paths for our customers. For our Open Source
              projects, there are community message boards and public support
              forums that are hosted with the projects on SourceForge.net, check
              the individual projects for details regarding each project.
            </p>

            <p>
              In addition, we offer two levels of email support. If you find an
              issue using one of our product, you can contact us at via email,
              and one of our support staff will attempt to isolate and resolve
              the issue as promptly as possible. If you are a paying customer,
              include your product license number, and you will go into our
              Priority Support queue. Priority Support issues are handled before
              any other issues in the queue's and will be addressed in the order
              they come in.
            </p>
          </div>
          <div class="footnotes is-hidden">
            <ol>
              <li id="fn:1"></li>
            </ol>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "view.support",
  components: {},
  data: function() {
    return {
      session: {}
    };
  },
  mounted() {
    this.session = this.$attrs.session;
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1px) {
  #view-support {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
      .footnote {
        font-size: 8pt;
        vertical-align: top;
      }

      .footnotes {
        font-size: 8pt;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  #view-support {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
